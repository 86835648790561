
import 'whatwg-fetch'
import config from 'react-global-configuration';
import format from 'date-fns/format';

const ProfileActions = {


  get( token, callback){

    fetch(config.get("API_PATH")  + "/players/me" , {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {

      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      callback(data)
    })
  },


  create( token, profile, callback){
    const item = {
      firstname:profile.firstname,
      givenname:profile.givenname,
      firstname_furigana:profile.firstname_furigana,
      givenname_furigana:profile.givenname_furigana,
      post_code:profile.post_code,
      address_1:profile.address_1,
      address_2:profile.address_2,
      address_3:profile.address_3,
      tel:profile.tel,
      mail:profile.mail,
      score:Number(profile.score),
      gender:profile.gender,
      birthday:format(new Date(profile.birthday), "yyyy-MM-dd")
    }

    fetch(config.get("API_PATH") + "/players" , {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      callback(data)
    })
  },


 
}

export default ProfileActions
