import React, { } from 'react'

import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';


function RegulationApp(props:PropsValue) {




  return(
   
    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="page-maincontent" > 
        <h1>サービス会員利用規約/プライバシーポリシー/免責事項</h1>

<h2>第１条（定義）</h2>
<p>
本規約において、以下の用語は以下に定める意味を有するものとします。
<ol>
  <li>「YOKUSURUサービス」とは、株式会社YOKUSURU（以下「当社」といいます）が運営・管理する「目土隊」のウェブサイト（以下「YOKUSURUサイト」といいます）において、当社が提供する会員向けの各種サービスをいいます。</li>
  <li>「本取引」とは、本会員がYOKUSURUサービスを利用して行う、ゴルフ場の役務提供の予約、ショッピング等の各種行為をいいます。</li>
  <li>「サービス提供者」とは、本取引の対象となる商品または役務を提供する者をいいます。</li>
  <li>「本会員」とは、本規約に同意の上、所定の方法によりYOKUSURUサービスへの会員登録した者をいいます。</li>
  <li>「本会員ＩＤ」とは、本会員がYOKUSURUサービスを利用するにあたって必要となる各本会員が保有すべきＩＤをいいます。</li>
  <li>「本規約等」とは、本規約、YOKUSURUサービスのご利用上のご注意、ガイドライン等の総称をいいます。</li>
  <li>「本会員情報」とは、本会員が当社に開示した本会員の属性に関する情報および会員の取引に関する履歴等の個人識別情報等をいいます。</li>
</ol>
</p>
<h2>第２条（会員の地位）</h2>
<p>
本会員は、当社が入会を承認した方に限り、YOKUSURUサービスを利用することができます。ただし、本会員が受けられるサービス内容が異なる場合や当社または提携先の事情または不可抗力等により事前の通知なくサービス内容に変更がありうることを予めご了承ください。<br/>
本会員は、本会員としての地位およびサービスの利用により当社に対して取得した一切の権利を譲渡、転貸、担保差入その他形態を問わず処分することはできません。<br/>
本会員情報の入力については、本会員となるご本人が行い、入力上の注意をよく読み、所定の入力欄に正確な情報を登録するものとします。なお、代理による登録は一切認められません。<br/>
過去に会員資格が取り消された方やその他、当社が相応しくないと判断した方からの会員登録についてはお断りする場合がございます。
</p>
<h2>第３条（会員登録手続）</h2>
<p>
未成年者は、入会にあたり保護者（親権者もしくは法定代理人）の同意を得なければならないものとします。当社は加入を希望する未成年者の保護者の同意を確認するため姓名などの必要な情報を要求することができ、同確認ができなかった場合、当社は未成年者のYOKUSURUサービスへの入会を承認することができません。<br/>
YOKUSURUサービスへの入会申込を行った者は次の全てを満たす必要があり、当社は次の条件を満たさないと判断した場合、YOKUSURUサービスへの入会を承認しない場合があります。
<ul>
  <li>日本国内外でのYOKUSURUサービスにかかる当社指定の決済手段を有すること。</li>
  <li>当社が別途定めるサービス提供対象となる権利を有していること。</li>
  <li>本規約および入会案内に同意頂いたこと。</li>
  <li>既にYOKUSURUサービスの利用申込および登録を行っていないこと。</li>
  <li>過去に当社により会員資格を取り消されたことのないこと。</li>
  <li>入会の申込みの内容に虚偽の記載、誤記、または記入漏れがないこと。</li>
  <li>日本国内に住所を有しない方は、当社からの配送物の受け取り等YOKUSURUサービスの全部または一部の提供が受けられないことに同意したこと。</li>
</ul>
本会員は、登録した情報に変更が生じた場合は、速やかに変更登録するものとします。<br/>
変更登録がなされなかったことにより生じた損害について、当社は一切責任を負いません。
</p>

<h2>第４条（会員ＩＤおよびパスワードの管理責任）</h2>
<p>
本会員ＩＤとパスワードが既に本サービス上登録されている情報と一致した場合、当社は、本会員による利用があったものとみなします。それらが、不正利用、盗用その他の事情により、本会員以外の第三者が利用された場合であっても、それにより生じた損害について、当社は一切責任を負いません。
</p>
<h2>第５条（会員の義務）</h2>
<p>
本会員は、関連法令・本規約の規定・利用案内および注意事項など、当社が通知する事項を守らなければならず、その他、当社の活動・業務の妨げになる行為は行わないものとします。<br/>
本会員は、会員ＩＤとパスワードは、他人に知られることのないように管理を徹底するよう努めるものとします。<br/>
本会員本人の会員ＩＤおよびパスワードが不正に使われたことまたは使われるおそれがあることを知った場合、本会員は直ちにまた必ず当社にその旨を知らせなければなりません。当社に通知しなかったことおよび通知の遅延によって生じる損害は会員本人が負担しなければなりません。 <br/>
本会員はYOKUSURUサービスの利用に伴い、次の各項目の行為を行うことはできません。 
<ul>
<li>YOKUSURUサービス運営を妨害する行為。</li>
<li>当社やその他の第三者を誹謗中傷し、その名誉または信用を毀損し、または不利益を与える行為、もしくはそのおそれを生じさせる行為。</li>
<li>犯罪的行為、もしくは犯罪的行為に結び付く行為、またはそのおそれのある行為</li>
<li>サービスの安定した運営を妨害する目的で多量の情報を伝送し、または広告性の情報を伝送する行為</li>
<li>情報通信設備の誤作動や情報の破壊を誘発させるコンピュータウィルスプログラムを流布する行為</li>
<li>他人の個人情報、本会員ＩＤ及びパスワードを盗用し、または不正に使用する行為</li>
<li>YOKUSURUサービスで入手した全てのデータ、情報、文章、音、映像、イラスト等について、著作権法で認められた私的利用の範囲を超えて、複製、販売、出版、放送可能化等のために利用する行為。</li>
<li>当社、他の会員、第三者の知的財産権、プライバシーもしくは肖像権その他の権利を侵害する行為。</li>
<li>営業行為および宗教団体の布教・勧誘行為、また政治団体の宣伝行為。</li>
<li>法令、行政指導、規約、道徳、慣習に反する行為。</li>
<li>その他、当社が合理的な理由に基づき不適切と判断する行為。</li>
</ul>
本会員が前項に定める禁止事項に該当した場合、その他本規約に違反した場合、当社は次の各号に定める全部または一部の措置を、何ら債務不履行責任を負うことなく講じることができるものとします。
<ul>
  <li>注意または警告などの改善要求</li>
  <li>YOKUSURUサービスの全部もしくは一部の利用制限または利用停止</li>
  <li>強制退会処分・損害賠償の請求</li>
</ul>
</p>
<h2>第６条（会員の退会）</h2>
<p>
本会員が退会を希望する場合には、本会員本人が退会の手続きを行っていただく必要があります。所定の退会手続完了後に、退会となります。
</p>
<h2>第７条（本規約とYOKUSURUサービス）</h2>
<p>
本規約は、すべての本会員に適用され、登録手続時および登録後におけるYOKUSURUグループサービスの利用するにあたって本会員は遵守しなければなりません。<br/>
本規約は、当社が提供するYOKUSURUサービスを、本会員において利用する一切の場合について適用するものとし、YOKUSURUサービスに入会した本会員は、本規約の内容を承諾したものとみなします。<br/>
YOKUSURUサービスには個別の規定も存在し、本規約と一体となって一つの規約を構成しています。そのため、各サービスを利用するためには、これらの全ての規約に同意して頂く必要があります。なお、本規約と個別規定が抵触する場合は、個別規定が優先するものとし、その他の部分については、本規約と個別規定が同時に適用されるものとします。<br/>
YOKUSURUサービスの提供に際しては、本規約等を掲載する場合があります。本会員はこれらサービス説明等も遵守し、YOKUSURUサービスを利用するものとします。<br/>
以下の【サイト一覧】に列挙するサイト等（以下「提携サイト」といいます）において、ゴルフ場の予約・商材の提供もしくは会員向けサービス等の提供がなされており、YOKUSURUサイトとの連携を図ることがあることを予めご了承ください。<br/>
【サイト一覧】
<ul>
  <li>目土隊　https:metsuchi.jp</li>
</ul>
</p>
<h2>第８条（サービスの中断、中止、終了） </h2>
<p>
当社は、以下の何れかに該当する場合、自らの判断によりYOKUSURUサービスの全部または一部を中断または中止することができるものとします。
<ul>
<li>YOKUSURUサービスに関連して、当社が設置または管理する設備の保守を定期的にもしくは緊急に行う場合</li>
<li>当社が設置または管理する設備の異状、故障、障害その他YOKUSURUサービスを本会員に提供できない事由が生じた場合</li>
<li>天災、事変、その他の非常事態が発生し、または、発生する恐れがある場合</li>
<li>その他YOKUSURUサービスの全部または一部を中断または中止すべき合理的な必要がある場合</li>
</ul>
前項の規定にかかわらず、当社は、いつでも当社自身の判断によって、YOKUSURUサービスの全部または一部の提供を終了することができるものとし、当社は本会員に対してYOKUSURUサービスの継続を保証するものではないものとします。<br/>
当社は理由の如何を問わず、YOKUSURUサービスの提供の中断、中止または終了によって生じた本会員の損害につき、一切責任を負わないものとします。
</p>
<h2>第９条（個人情報の取り扱い）</h2>
<p>
当社は、第２項に記載する活動を行なう目的で、本会員より氏名、性別、住所、電話番号、電子メールアドレス、予約履歴、購入履歴等の個人識別情報（以下「個人情報」といいます。）をご提供いただき、それを記録させていただきます。 
当社は、本会員よりご提供を受けた個人情報を、以下の目的に利用させていただきます。 •YOKUSURUサービスの提供
<ul>
  <li>本会員からお申し込みのあったチケット・商材販売ならびにそれに付随するサービスの電子メール・ダイレクトメール等による案内（公演の変更・中止・追加発売、次回発売などの情報提供、ただし、全ての公演についての変更・中止等のご案内を行うことを約束するものではありません）</li>
  <li>当社が、取り扱うサービスや商材等の電子メール・ダイレクトメール等による案内</li>
  <li>YOKUSURUサービスに関するユーザーサポート（お問合せ対応など）</li>
  <li>その他YOKUSURUサービスに関連または付随するサービスの提供</li>
  <li>当社の新商品・サービス・キャンペーン・プレゼントなどのご紹介</li>
  <li>本会員にとって有用と思われる当社提携先の商品・サービスをご紹介するためのメールマガジン・ダイレクトメール・FAXの配信・発送</li>
  <li>当社ならびに当社提携先の商品・サービスのためのマーケティング活動</li>
  <li>本会員からの開示・訂正・削除等のお求めを頂いた場合の回答</li>
  <li>本会員のサービスの利用動向等の統計資料の作成</li>
  <li>本会員に個別にご承諾いただいた目的への利用</li>
</ul>
本会員は、YOKUSURUサービスにおいて、本会員ＩＤの有効性および取り扱うチケット・商材等の当選ないし引渡等を確認するため、当社とYOKUSURUサービス提供者、及びチケット発券元等との間で個人情報の交換を行なうことに予め同意するものとします。<br/>
当社は、以下のいずれかに該当する場合には、個人情報の全部又は一部について、本会員の個別の同意を得ることなく、第三者に対して開示することができるものとし、本会員はこれを承諾するものとします。 
<ul>
  <li>個人情報の保護に関する法律等法令に基づく場合</li>
  <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
  <li>国の機関若しくは地方公共団体またはその委託を受けた者が、法令の定める事務を遂行することに対して協力をする必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
  <li>第２項に記載する事項を実行するために、当社が業務委託先に必要な範囲で開示する場合</li>
</ul>
本会員が、個人情報の照会・修正・削除をご希望される場合には、合理的範囲内ですみやかに対応させていただきます。ただし、法令又は規約等により、個人情報の保存期間が定められているときは、保存期間の経過後に削除します。<br/>
上記のほか、YOKUSURUサービスにおける本会員の個人情報の取り扱いに関する事項については当社が別途定めるプライバシーポリシーに従うものとし、本会員はこれに従うものとします。
</p>
<h2>第１０条（通知）</h2> 
<p>
当社が本会員に対して通知を行う場合、本会員に対する個別の通知または、YOKUSURUサービスのサイト上に通知事項を掲載するものとし、当該掲載を行った時点より効力を発するものとします。個別の通知方法の場合、当社は、本会員が予め登録又は告知したメールアドレスその他の連絡先に通知を行えば足りるものとします。
</p>
<h2>第１１条（損害賠償） </h2>
<p>
本会員は、YOKUSURUサービスの利用において、自己の責めに帰すべき事由により第三者に対して損害を与えた場合、これを賠償する責任を負います。
</p>
<h2>第１２条（譲渡禁止） </h2>
<p>本会員は、YOKUSURUサービスの利用権限、その他利用契約上の地位を有償無償問わず他者に譲渡、贈与、または貸与することはできません。また、これを担保として提供することもできません。</p>

<h2>第１３条（YOKUSURUサービスにおける取引）</h2>
<p>本会員と本サービス提供者との取引は、本会員とサービス提供者間の直接取引となります。
当社は、当該取引におけるサービス提供者となる場合を除き、取引の当事者とはなりません。よって、取引に際し、万が一、トラブル等が生じた場合には、本会員とサービス提供者との間で解決いただくものとし、当社は取引に関する責任は一切負いません。
</p>
<h2>第１４条（免責）</h2>
<p>
本会員はYOKUSURUサービスを専ら自らの責任において利用するものとします。当社は本会員がYOKUSURUサービスの利用を通じて得た情報、資料等による損害に関して一切の責任を負いません。また、当社は、提供した情報、資料、事実の信頼度及び正確度など、内容に関しても一切の責任を負いません。 <br/>
当社は本会員に起因する事由によるサービス利用の障害について責任を負いません。<br/>
当社は天災地変、法令・行政指導・監督官庁の指導、事故、不可抗力等によりYOKUSURUサービスを提供することができない場合には、サービス提供に関する責任が免除されます。 <br/>
当社はYOKUSURUサービス利用を通じて会員が利用するコンピュータ及びネットワーク環境において発生したどのような損害に対しても責任を負いません。 <br/>
天災地変、悪疫流行、交通ストライキ、道路・鉄道・航空等の交通事情、法律命令、行政措置、その他不可避的事由により、当該イベントが実施されなかった場合、当社は一切の責任を負いません。<br/>
当社は、お客様がYOKUSURUサービスのご利用に当たり入力、送信した個人情報については、暗号化処理等を行い、厳重に管理し機密保持に十分な注意を払いますが、情報の漏洩、消失、他者による改ざん等が完全に防止されることの保証は行わないものとします。 <br/>
当社は、本サイトからのリンク先である外部のウェブサイトの内容を保証いたしません。またそれらのウェブサイトを運営している会社との特別な業務上の関係、またはそれらの会社が提供する商品・サービス等を推奨することを意図しておりません。<br/>
当社以外の外部サイトに当社のロゴ、社名、本サイトへのリンクを含んでいたとしても、それらのサイトは当社とは独立して運営されており、当社は内容に関して責任を負いません。
</p>
<h2>第１５条（協議事項） </h2>
<p>
本規約に定めのない事項または本規約の解釈について疑義が生じた場合、本会員および当社は双方誠意を持って協議の上これを解決するものとします。
</p>
<h2>第１６条（規約の効力及び変更） </h2>
<p>
当社は、合理的な範囲・方法により、本会員の承諾を得ることなく本規約を適宜変更できるものとし、本会員には、予めこれをご承諾いただくものとします。 <br/>
本規約が変更された場合には、YOKUSURUサービスのサイト上に掲示し、それ以降は、変更後の規約が適用されるものとします。
</p>
<h2>第１７条（強制退会） </h2>
<p>
本会員が本規約に違反した場合、当社は事前に通知することなく本会員を強制退会処分とすることができます。この場合、本会員がすでに入金したチケット・商材の購入代金、YOKUSURUサービスの返還は一切行いません。退会の時点で支払い義務が発生しているものは、退会後もなお支払い義務を逃れられないものとします。
</p>
<h2>第１８条（自主退会） </h2>
<p>
本会員が退会を希望する場合は別途定める方法にて手続きを行うものとします。本会員は退会にあたって、本会員がすでに入金した商材の購入代金、YOKUSURUサービスの返還は一切行いません。退会の時点で支払い義務が発生しているものは、退会後もなお支払い義務を逃れられないものとします。 <br/>
退会後、再入会を希望した場合、新規登録となり、一切のサービスの引継ぎは行わないものとします。
</p>
<h2>第１９条（紛争解決） </h2>
<p>
YOKUSURUサービスに関して、本規約により解決できない問題が生じたときは、当社と本会員との間で誠意をもって話し合い、これを解決するものとします。なお、紛争を訴訟によって解決するときには、東京地方裁判所を第一審の管轄裁判所とします。
</p>
</div>
</main>
<Footer />
</div>

  )
}

export default RegulationApp
