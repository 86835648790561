import { useState } from 'react';
import ProfileActions from './ProfileActions';


function useMyProfileState() {

  const [item, setItem] = useState(null);

  const handler = (data) => {
    setItem(parseItem(data))
  }
  const get = (token) => {
    ProfileActions.get(token, handler)
  };

  const parseItem = (data) => {
    if(data && data.id){
      data.furigana = data.name_furigana
      data.birthday = new Date(data.birthday)
    }
    return data
  }

  return {profile:item, getProfile:get};
}

export default useMyProfileState