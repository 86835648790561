
import 'whatwg-fetch'
import config from 'react-global-configuration';


const OfferActions = {

  getList(park_id, start_date, end_date, callback){
    
    fetch(config.get("API_PATH")  + "/parks/" + park_id + "/offers?start_date=" + start_date + "&end_date=" + end_date , {
      method: "GET",
      headers: {
       
      }
    }).then(function(response) {

      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {

      if(data && !data.error){
        callback(data)
      }else{
        console.log("error")
      }
    })
  },

  getDetail(offer_id, callback){
    
    fetch(config.get("API_PATH")  + "/offers/" + offer_id , {
      method: "GET",
      headers: {
       
      }
    }).then(function(response) {

      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      callback(data)
    })
  },



  /**

  create(company_id, assign_id, item , callback){
    const token = AuthStore.getToken()
    fetch(config.get("API_PATH") + config.get("API_COMPANY") + "/" + company_id + "/assign/" + assign_id + "/mentenances" , {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){

        callback(data)
      
      }else{
        console.log("error")
        
      }
    })
  },


  update(company_id, assign_id, item , callback){
    const token = AuthStore.getToken()
    fetch(config.get("API_PATH") + config.get("API_COMPANY") + "/" + company_id + "/assign/" + assign_id + "/mentenances/"  + item.id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){

        callback(data)
      
      }else{
        console.log("error")
        
      }
    })
  },

 


  delete( company_id, assign_id, item , callback){
    const token = AuthStore.getToken()
    fetch(config.get("API_PATH") + config.get("API_COMPANY") + "/" + company_id + "/assign/" + assign_id + "/mentenances/"  + item.id , {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    
    }).then(function(response) {
      if(response.status === 204  ){
        return {}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      
      if(data && !data.error){
        callback()
      }else{
        console.log("error")
      }
    })
  },
   */
}

export default OfferActions
