import React, {   useEffect ,useState} from 'react'
 

function YearSelect(props:PropsValue){
  
  const [options, setOptions] = useState(null)
 
  useEffect(() => { 
    const today = new Date()
    const start_year = props.start_year ? props.start_year : 1930
    const end_year = props.end_year ? props.end_year : today.getFullYear()
    const duration = end_year - start_year
    const _options =  (new Array(duration)).fill(0).map((n, index) => {
      const _value = Number(start_year + index)
      const selected = _value === Number(props.defaultValue) ? "selected" : null
      return  <option selected={selected} value={_value} >{_value}</option>
    } )
    
    setOptions(<select   onChange={(e) => props.changeHandler("year",e)}>{_options}</select>)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.defaultValue]);


  return (  
    <div className={"year " + props.className ? props.className : ""}>
      {options}
    </div>  
  );
}

export default YearSelect

