import React, {  useEffect, useState } from 'react'
import ProfileItem from '../profile/ProfileItem';
import CreateUserBtn from './CreateUserBtn';
import useProfileState from '../profile/useProfileState';
import Auth from '../login/Auth';
import { useCookies } from 'react-cookie';
import {  format } from 'date-fns';

function ProfileCreateItem(props:PropsValue) {

  const [cookies, setCookie] = useCookies(['profile','user', 'user_token']);
  const {profile, updateProfile,createProfile} = useProfileState(props.id)

  const [updated_at, setUpdatedAt] = useState(new Date())
  const [auth, setAuth] = useState(new Auth())
  const [message, setMessage] = useState(null)


  useEffect(() => { 

    if(cookies["profile"]  && cookies["user"]){
      createProfile(cookies["profile"])
      const birthday = new Date(cookies["profile"].birthday)
      updateProfile("birthday", new Date(format(birthday, "yyyy-MM-dd")))
      setAuth(new Auth(cookies["user"]))
      setUpdatedAt(new Date())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.updated_at,cookies]);



  function changeHandler(name,value){
    updateProfile(name,value)
  } 

  function autoUpdateHandler(){
    setUpdatedAt(new Date())
  }

  function changeAuthHandler(name,value){
    setAuth(auth => auth.set(name,value))
  }


  function create(){
    
    if(!cookies["profile"] && ( !auth.password || auth.password.length < 6)){
      setMessage(<div className="message">パスワードを6文字以上で入力してください。</div>)
      return false
    }
    if(!cookies["profile"] && ( auth.password !== auth.password_confirm)){
      setMessage(<div className="message">パスワードが一致しません</div>)
      return false
    }


    if(!profile.firstname || !profile.firstname_furigana  || !profile.givenname || !profile.givenname_furigana  || !profile.address_1 || !profile.address_2 || !profile.address_3 || !profile.tel || !profile.mail || !profile.gender || !profile.birthday){ 
      console.log(profile)
      setMessage(<div className="message">全ての項目を入力してください。</div>)
       return  false
    }

    //TODO 更新
    setCookie("user",auth,{ path: '/' })
    setCookie("profile",profile,{ path: '/' })

    window.location.href = "/user/confirm/" + props.secret
  }

  return(
    <div className="login-item-form">
      <ProfileItem  changeHandler={changeHandler} changeAuthHandler={changeAuthHandler} profile={profile} auth={auth} updated_at={updated_at} updated={autoUpdateHandler}/>
      {message}
      <CreateUserBtn text="確認ページへ" createHandler={create} />
    </div>

  )
}

export default ProfileCreateItem

