import { useState, useEffect } from 'react';
import ParkActions from '../ParkActions';

function useListState(params) {
  const [list, setList] = useState([]);

  useEffect(() => {
    function handleStatusChange(list) {
      setList(list);
    }

    ParkActions.getList( params,handleStatusChange);
    
  },[params]);

  

  return list;
}

export default useListState