import React, { } from 'react'


function BookProgress(props:PropsValue) {
  



  return(

    <div className={"book-confierm-list"}>
      <div className={props.type === "confirm" ?  "book-btn selected": "book-btn"}>予約内容の最終確認</div>
      <div className={props.type === "success" ?  "book-btn selected": "book-btn"}>予約完了</div>
    </div>

  )
}

export default BookProgress
