import { useState } from 'react';
import QuestionActions from "./QuestionActions"

function useQuestionState() {
  const [item, setItem] = useState(null);

  const handleStatusChange  = (data) => {
    setItem(data)
  }
  const create = (item) => {
    QuestionActions.create( item, handleStatusChange, handleStatusChange);
  }


  return {create_question_result:item, createQuestion:create};
}

export default useQuestionState