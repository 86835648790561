import React, {   useEffect ,useState} from 'react'

 

function DaySelect(props:PropsValue){
  
  const [options, setOptions] = useState(null)

  useEffect(() => { 
    const start_date = 1
    const _options =  (new Array(31)).fill(0).map((n, index) => {
      const _value = Number(start_date + index)
      const selected = _value === Number(props.defaultValue) ? "selected" : null
      return <option selected = {selected} value={_value} >{_value}</option>
     })
    setOptions(<select onChange={(e) => props.changeHandler("day",e)}>{_options}</select>)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.defaultValue]);
  

 



  return (
    <div className={props.className}>
     
      {options}
     
    </div>
  );
}

export default DaySelect

