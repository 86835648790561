import React  from 'react'
import Utile from '../../utiles/Utile'
import config from 'react-global-configuration';

function ParkListItem(props:PropsValue) {



   const listboxImage = {
    width: '100%',
    objectFit: "cover",
   }

   const name = {
    marginTop:"25px",
    marginBottom:"20px",
    fontSize:"22px"
   }



   const areaCircle = {
    position:"absolute",
    top:"14px",
    right:"27px",
    width:"68px",
    height:"68px",
    borderRadius:"34px",
    fontWeight:"bold",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
   }
   const areaCircleB1 = Object.assign({
    backgroundColor:"#fe550a",
    color:"#fff"
   },areaCircle )


   function displayDetail(){
    window.location.href = "/park/" + props.item.id
   }


  return(

      <div className={"listbox"} key={props.key} onClick={displayDetail}>
        <div className="imagebox">{props.item.medias.length > 0 ? <img  style={listboxImage} src={props.item.medias[0].url} alt="メインイメージ" /> : null}</div>
        <div  style={name}>{props.item.name}</div>
        <div>
            アクセス : {props.item.access}<br/>
            住所 : {props.item.address.address_less}
        </div>
        <div style={areaCircleB1}>{Utile.getLabelByValue(config.get("PREFS") , props.item.address.address_todofuken)}</div>
      </div>

  )
}

export default ParkListItem

