import React, { useState } from 'react'

import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import ValueItem from '../form/ValueItem';
import Progress from './Progress';
import CreateUserBtn from './CreateUserBtn';
import CreateUserForm from "./CreateUserForm"
import CreateUserActions from "./CreateUserActions"
import {Oval} from 'react-loader-spinner'

function CreatedPreUserApp(props:PropsValue) {
 
 

  const [item, setItem] = useState(new CreateUserForm())
  const [message, setMessage] = useState(null)
  const [is_btn, setIsBtn]   = useState(true)
  const [is_loading, setIsLoading]   = useState(false)
  function changeHandler(name,value){
    setItem(item => item.set(name,value))
  }

  function actionHandler(data){
    if(data.error){
      const message = data.error.code && data.error.code === 301 ? "エラーが発生しました。同じメールアドレスがすでに使われている可能性があります。ご確認ください。" : "エラーが発生しました。メールアドレスの形式などをご確認ください。"
      setMessage(<div className="message">{message}</div>)
      setIsBtn(true)
      setIsLoading(false)
      return 
    }else if(data.id){
      window.location.href = "/user/create/sent"
    }
  }

  function createHandler(){
    setMessage(null)
    setIsBtn(false)
    setIsLoading(true)
    CreateUserActions.create(item.mail,actionHandler)
  }
   
  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          <div className="create-title" >会員登録：メールアドレス入力</div>
          <Progress type="mail" />
          <div className="form">
            <ValueItem name="mail" placeholder="" label="メールアドレス"  className="login-item" value={item.mail} changeHandler={changeHandler} />
          </div>
          {message}
          {is_btn ? <CreateUserBtn text="メール送信" createHandler={createHandler} /> : null }
          {is_loading ? <Oval color="#3aab5e" height={40} width={40} strokeWidth={4}/> : null} 
        </div>
        
      </main>
      
      <Footer />
    </div>

  )
}

export default CreatedPreUserApp
