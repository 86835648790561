import { useState } from 'react';

import PostCodeActions from './PostCodeActions';

function usePostCodeState(code) {
  const [address, setData] = useState([]);


  function handleStatusChange(d) {
    setData(d);
  }


  const updatePostCode = (code) => {

   PostCodeActions.getAddress(code, handleStatusChange)
  };
  

  return {address:address, updatePostCode:updatePostCode};
}

export default usePostCodeState