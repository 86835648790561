import React, {  useEffect } from 'react'
import  logo from "../image/logo_blck.svg"


function Footer(props:PropsValue) {
  const footerwrap = {
    width:"100%",

    backgroundColor:"#f2f2f2"
  }
  const footer = {
    width:"1440px",
    maxWidth:"100%",
    margin:"0 auto 0 auto",
    padding:"15px 40px",
    textAlign:"center"
   }


  const logoBox = {
      marginTop:"40px"
   }

   const copyright = {
    fontSize:"12px",
    color:"#85858a"
   }
  
  useEffect(() => {

    return function cleanup() {

    };
  },[]);

  return(
    <footer style={footerwrap}>
      <div style={footer}>
        <div style={logoBox}><img width="120px" src={logo} alt="ロゴ"/></div>
       

        <ul className="footer_menu">
          <li ><a href="/company">運営会社</a></li>
          <li ><a href="/regulation">利用規約/プライバシーポリシー/免責事項</a></li>
          <li ><a href="/question">お問い合わせ</a></li>
        </ul>
        <div style={copyright}>Copyright {(new Date()).getFullYear()} YOKUSURU Inc. All Rights Reserved.</div>
      </div>
    </footer>
  )
}

export default Footer


/*
import  logo_fb from "../image/logo_fb.svg"
import  logo_tw from "../image/logo_tw.svg"
import  logo_insta from "../image/logo_insta.svg"
 <ul className="footer_sns">
          <li ><img height="30px" src={logo_fb} alt="ロゴ"/></li>
          <li ><img height="30px" src={logo_tw} alt="ロゴ"/></li>
          <li ><img height="30px" src={logo_insta} alt="ロゴ"/></li>
        </ul> */