import { useState } from 'react';
import CreateBookActions from "./CreateBookActions"

function useCreateBookState() {
  const [item, setItem] = useState();

  const handleStatusChange  = (data) => {
    setItem(data)
  }

  const create = (book, token) => {
    CreateBookActions.create(book, token,handleStatusChange )
  }

  return {created_book:item, createBook:create};
}

export default useCreateBookState