import React from 'react'
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import Progress from './Progress';
import CreateUserBtn from './CreateUserBtn';
function CreateUseSuccessApp(props:PropsValue) {
 
  const form = {
    margin:"60px auto 60px auto"
  }

  const message = {
    margin:"60px auto 60px auto",
    textAlign:"center",
    fontSize:"20px",
    color:"#666666",
    width:"100%"
  }

  const message_mini = {
    margin:"60px auto 60px auto",
    textAlign:"center",
    color:"#666666",
    width:"100%"
  }


  function goToMyPage(){
    window.location.href = "/mypage/profile"
  }

  function backToTop(){
    window.location.href = "/"
  }
  
  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          <div className="create-title" >会員登録：登録完了</div>
          <Progress type="success" />
          <div style={form}>
          <p style={message}>
            会員登録ありがとうございます。
          </p>
          <CreateUserBtn text="トップページに戻る" createHandler={backToTop} />

          <p style={message_mini}>
            登録アドレスにメールをお送りしました。
          </p>

          <CreateUserBtn text="マイページで確認" createHandler={goToMyPage} />
          </div>
          
        </div>
        
      </main>
      
      <Footer />
    </div>

  )
}

export default CreateUseSuccessApp
