import React, { } from 'react'
import  backgroundImage from "./header.svg"


function HeaderImage(props:PropsValue) {

  const bigBackgroundWrap = {
    display: 'flex',
    justifyContent: 'center'
   }



  return(

      <div style={bigBackgroundWrap}>
        <div className="sml-header">
          <img src={backgroundImage} alt="hader" />
        </div>
      </div>

  )
}

export default HeaderImage

