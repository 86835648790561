import React, {  } from 'react'

import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';


function QuestionSuccessApp(props:PropsValue) {


  return(
    
   
    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="page-maincontent" > 
          <h1>お問い合わせ</h1>
          <div className="question"> 
            お問い合わせを送信いたしました。担当からお返事いたしますので、しばらくお待ちください。
          </div>
        </div>
      </main>
<Footer />
</div>

  )
}

export default QuestionSuccessApp
