
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

const AuthActions = {

 
  auth( auth, callback){
  

    //Login
    var data = new FormData() 
    data.append('grant_type', "password")
    data.append('username', auth.username)
    data.append('password', auth.password)
    data.append('client_id', "metsuchi")
    data.append('client_secret', "metsuchi")
    data.append('scope', "player")    
  

    fetch(config.get("API_PATH") +"/oauth/auth?", {
      method: 'POST',
      body: data
    }).then(function(response) {
  
      if(response.status === 200){
        return response.json()
      }else {
        return false
      }
    }).then(function(data) {
      
      if(data.access_token){
        cookie.save('user_token', data.access_token, { path: '/' })
        cookie.save('refresh_token', data.refresh_token, { path: '/' })
        callback(data)
      }else{
       
        cookie.remove('user_token',{ path: '/' })
        cookie.remove('refresh_token',{ path: '/' })
        callback({error:1})
      }
    });
  },

  getToken(){
   
    const token  =  cookie.load("user_token")
    
    return token ? token  : {error:1}

    //return {erro,r:1}
  },

  logout(){
    console.log("logout")
    cookie.remove('user_token',{ path: '/' })
    cookie.remove('refresh_token',{ path: '/' })
  }
 
}

export default AuthActions
