import React, {  useCallback, useEffect ,useState} from 'react'


import ja from 'date-fns/locale/ja'
import DatePicker , {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from 'react-select'
import Utile from "../utiles/Utile"
import YearSelect from "./YearSelect"  
import MonthSelect from './MonthSelect';
import DaySelect from './DaySelect';

function ValueItem(props:PropsValue){
  
  const [content, setContent] = React.useState(null)
  const [data, setData] = useState(props.defaultValue)

  registerLocale('ja', ja);
  const staticHandlerChange = useCallback(handleChange,[props])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const staticHandleChangeDate = useCallback(handleChangeDate, [props])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const staticHandleChangeSelect = useCallback(handleChangeSelect, [props])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const staticHandleChangeRadio = useCallback(handleChangeRadio, [props])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const staticGetItem = useCallback(getItem, [props,data])


  
  function getItem(){
    
    switch(props.type) {
       case "select" : {
        return (
          <Select 
            styles={props.style}
            options={props.options} 
            value={props.defaultValue ?  Utile.getObjectByValue(props.options, props.defaultValue) : undefined} 
            placeholder={props.placeholder} 
            onChange={staticHandleChangeSelect}
          />
        )
      }
      case "radio" :
      case "radio-number" :{
        const options = props.options.map(item => {
          const checked = item.value === data  ? "checked" : false
          return <div><input type="radio" name={props.name} value={item.value}  onChange={staticHandleChangeRadio} checked={checked}/>{item.label}</div>
        })
        return(
          <div className="radio"  >{options}</div>
        )
      }
      //case "number" : return ())

      case "password" : return (
        <div className="input"> <input type="password" placeholder={props.placeholder} name={props.name} onChange={staticHandlerChange}  value={props.defaultValue} /> </div>
      )
      case "password_read" : {
       
        
        return (
        <div className="input">{props.defaultValue ? (new Array(props.defaultValue.length)).fill(0).map(item => {return  "*"}) : null}</div>
      )}
      

      case "date": {
        return ( <div className="date"><DatePicker  showYearDropdown yearDropdownItemNumber={100} 
        scrollableYearDropdown locale='ja' placeholderText={props.placeholder} dateFormat="yyyy年M月dd日" selected={data} onChange={(d) => staticHandleChangeDate(d)} /></div>)
      }

      case "split_date": {

   
        const default_year = props.defaultValue ? props.defaultValue.getFullYear() : null
        const default_month = props.defaultValue ? props.defaultValue.getMonth() + 1 : null
        const default_date = props.defaultValue ? props.defaultValue.getDate() : null
   
        return ( <div className="date_break">
          <YearSelect changeHandler={changeSeparetDateHandler} defaultValue={default_year} />年
          <MonthSelect changeHandler={changeSeparetDateHandler} defaultValue={default_month} />月
          <DaySelect changeHandler={changeSeparetDateHandler} defaultValue={default_date} />日
        </div>)
      }
       
      //case "datetime" : return ( )
      // case "date_read" : return ( )
      // case "date_time_read" : return ( )
      case "read" : return (
        <div className="input">{props.defaultValue}</div>
      )

      case "read_select" : return (
        <div className="input">{props.defaultValue ?  Utile.getLabelByValue(props.options, props.defaultValue ) : undefined} </div>
      )
      case "textarea" :
        return (
          <div className={"input"}> <textarea placeholder={props.placeholder} name={props.name} onChange={staticHandlerChange}  defaultValue={props.defaultValue} /> </div>
        )
      default :  return (
        <div className={"input"}> <input type="text" placeholder={props.placeholder} name={props.name} onChange={staticHandlerChange}  defaultValue={props.defaultValue} /> </div>
      )
    }
  }

  useEffect(() => { 

    setContent(staticGetItem())
  },[props.defaultValue, props.options, staticGetItem])


  function handleChangeDate(value){

    props.changeHandler(props.name,value)
    setData(value)
  }

  function handleChangeRadio(e){
    const value = e.target.value
    var radio_value = value 
    if(props.type === "radio-number" ) radio_value = Number(value)
    
    props.changeHandler(props.name,radio_value)
    setData(radio_value)
    setContent(getItem())
  }

  function changeSeparetDateHandler(year_type, e){

    const _value = Number(e.target.value)
    
    const target_date = props.defaultValue
    switch(year_type) {
      case "year" : {
        target_date.setFullYear(_value)
        break
      }
      case "month" : {
        target_date.setMonth(Number(_value) - 1)
        break
      }
      case "day" : {
        target_date.setDate(_value)
        break
      }
      default :{break}
    }
    console.log(target_date)
    props.changeHandler(props.name,target_date)
  }
  /**
  function handleChangeDateTime(value){
    props.changeHandler(props.name, format(value,"yyyy-MM-dd HH:mm:00"))
  } */

      
  function handleChange(e){
    props.changeHandler(props.name, e.target.value)
    //setItem(e.target.value)
  }

  /**
  function handleChangeNumber(e){

    props.changeHandler(props.name, Number(e.target.value))
  }
  */
  /**
  function handleChangeFile(e){
    props.changeHandler(props.name, e.target.files[0])
  }  */
  
  function handleChangeSelect(e){
    props.changeHandler(props.name, e.value)
  }




  return (
    <div className={props.className}>
      <label>{props.label}</label>
      {content}
    </div>
  );
}

export default ValueItem

