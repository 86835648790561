
import 'whatwg-fetch'
import config from 'react-global-configuration';


const CreateBookActions = {


  create(book, token, callback){
    fetch(config.get("API_PATH") + "/books" , {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(book)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  response.json()
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
       callback(data)
    })
  },

}

export default CreateBookActions
