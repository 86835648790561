
import 'whatwg-fetch'
import config from 'react-global-configuration';


const PostCodeActions = {

  getAddress(code, callback){


    if(!code || code.length !== 7) return null

    fetch(config.get("POSTCODE_PATH")  +  code  + "?fields=prefCode,city,town", {
      method: "GET",
      headers: {
        "apikey":config.get("POSTCODE_KEY")
      }
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {

      if(data && !data.error){
        callback(data)
      }else{
        console.log("error")
      }
    })
  },


}

export default PostCodeActions
