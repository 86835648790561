import React, { useCallback, useEffect, useState } from 'react'
import ProfileItem from '../profile/ProfileItem';
import BigBtn from '../parts/BigBtn';
import useMyProfileState from './useMyProfileState';
import useAuthState from '../login/useAuthState';

function ProfileUpdateItem(props:PropsValue) {

  const {token, auth} = useAuthState()  
  const {profile, getProfile} = useMyProfileState()
  const [updated_at, setUpdatedAt] = useState(new Date())
  const [message, setMessage] = useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableGetProfile = useCallback(getProfile,[])

  useEffect(() => {
    
    setUpdatedAt(new Date())
  },[profile]);


  useEffect(() => {
    if(token && token.error){
     window.location.href = "/login"
    }
    if(token){
      stableGetProfile(token)
    }

  },[token,stableGetProfile]);

  const staticAuth = useCallback(auth, [auth])
  useEffect(() => {
    staticAuth()
  },[staticAuth]);



  

  function changeHandler(name,value){
    //updateProfile(name,value)
  } 

  function autoUpdateHandler(){
    //setUpdatedAt(new Date())
  }



  function update(){
    
    setMessage(null)

  }

  return(
    <div>
      <ProfileItem  changeHandler={changeHandler}  profile={profile} auth={auth} updated_at={updated_at} updated={autoUpdateHandler}/>
      {message}
      <BigBtn text="更新" createHandler={update} />
    </div>

  )
}

export default ProfileUpdateItem

