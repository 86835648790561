import React ,{useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import Progress from './Progress';
import ProfileCreateItem from './ProfileCreateItem';

function CreateProfileApp(props:PropsValue) {
  const query = new URLSearchParams(useLocation().search);
  const {id} = useParams()
  const  error = (query.get("error"))


  useEffect(() => { 
    //removeAuthInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          <div className="create-title" >会員登録：基本情報入力</div>
          <Progress type="detail" />

          {error === "1" ? <div className='message'>登録エラーです。登録内容を確認してください。</div> : null}
          <ProfileCreateItem secret={id} />

          
        </div>
        
      </main>
      
      <Footer />
    </div>

  )
}

export default CreateProfileApp
