import React, {  } from 'react'
import config from 'react-global-configuration';

function CreateUserBtn(props:PropsValue) {
  const boxWrap= {
    width:"100%",
    display: 'flex',
    justifyContent:"center"
  }
  const btnBox = {
    display: 'flex',
    width:"576px",
    height:"70px",
    backgroundColor: config.get("color_light_green"),
    borderRadius:"7px",
    justifyContent:"center",
    alignItems:"center",
    color:"#fff",
    fontSize:"25px"
   }




  function moveTo(){
    window.location.href = "/user/create"
  }

  return(
    <div  style={boxWrap}>
      <div style={btnBox} onClick={moveTo}>
        会員登録はこちらから
      </div>
    </div>
  )
}

export default CreateUserBtn

