import React, { useState, useEffect, useCallback } from 'react'

import { useParams } from 'react-router-dom';
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import Progress from './Progress';
import CreateUserActions from "./CreateUserActions"

function CreateUserApp(props:PropsValue) {
  const {id} = useParams()
  
  const [message, setMessage] = useState(null)
  const stableActionHandler = useCallback(actionHandler,[id])
  useEffect(() => {
    CreateUserActions.validate(id,stableActionHandler)
  },[stableActionHandler,id]);


  function actionHandler(data){
    if(data.error){
      setMessage(<div className="message">URLが有効ではありません。再度ご登録をお願いいたします。</div>)
    }else if(data){
      window.location.href = "/user/create/profile/" + id
    }
  }

   
  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          <div className="create-title" >会員登録：登録メールアドレスの確認</div>
          <Progress type="preuser" />
          
          {message}
          
        </div>
        
      </main>
      
      <Footer />
    </div>

  )
}

export default CreateUserApp
