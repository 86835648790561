import React from 'react'

import config from 'react-global-configuration';
import arrow_png from "../image/arrow.png"

function Progress(props:PropsValue) {
  

  const selected = {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width:"200px",
    height:"40px",
    backgroundColor:config.get("color_light_green"),
    margin:"0 10px",
    color:"#fff"
  }


  const normal = {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width:"200px",
    height:"40px",
    border: "1px solid " + config.get("color_light_green"),
    margin:"0 10px"
  }

  const arrow= {

  }



  return(

    <div className="progree-list">
      <div style={props.type === "mail" ? selected : normal}>メールアドレスの入力</div>
      <div style={arrow}><img src={arrow_png} alt="矢印" /></div>
      <div style={props.type === "preuser" ? selected : normal}>仮登録完了</div>
      <div style={arrow}><img src={arrow_png} alt="矢印"  /></div>
      <div style={props.type === "detail" ? selected : normal}>基本情報入力</div>
      <div style={arrow}><img src={arrow_png} alt="矢印"  /></div>
      <div style={props.type === "confirm" ? selected : normal}>確認</div>
      <div style={arrow}><img src={arrow_png} alt="矢印"  /></div>
      <div style={props.type === "success" ? selected : normal}>登録完了</div>
    </div>

  )
}

export default Progress
