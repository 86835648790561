import React, { } from 'react'

import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import "./page.css"

function CompanyApp(props:PropsValue) {


  return(
   
    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="page-maincontent" > 
          <h1>会社概要</h1>
          <div  className="question">
              <div className="question-item">
                <div className="label">会社名（法人名）</div>
                <div className="item-content">
              株式会社YOKUSURU
                </div>
              </div>


              <div className="question-item">
                <div className="label">代表者</div>
                <div className="item-content">
                楠瀬 健太
                </div>
              </div>

              <div className="question-item">
                <div className="label">所在地（住所）</div>
                <div className="item-content">
                東京都中央区銀座1丁目12番4号N&E BLD.6F
                </div>
              </div>

              <div className="question-item">
                <div className="label">設立</div>
                <div className="item-content">
                2023年6月20日
                </div>
              </div>
              <div className="question-item">
                <div className="label">事業内容</div>
                <div className="item-content">
                インターネットを利用した各種情報提供サービス、 ポータルサイトの運営、情報処理サービス業及び情報提供サービス業
                </div>
              </div>
              <div className="question-item">
                <div className="label">資本金</div>
                <div className="item-content">
                250万円
                </div>
              </div>

            </div>
          </div>
      </main>
<Footer />
</div>

  )
}

export default CompanyApp
