import React, { useEffect, useState } from 'react'

import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import useAuthState from '../login/useAuthState';
import MyPageMenu from './MyPageMenu';
import BookList from '../book/list/BookList';

function CommingBookApp(props:PropsValue) {

  const {token, auth} = useAuthState()
  const params = {target_type:"coming"}
  const [user_token, setUserToken] = useState(null)
  
  useEffect(() => {
    if(token && token.error){
     window.location.href = "/login"
    }
    setUserToken(token)
  },[token]);


  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          <MyPageMenu type="coming" />
          <BookList token={user_token} params={params} />
        </div>
      </main>
      
      <Footer />
    </div>

  )
}

export default CommingBookApp
