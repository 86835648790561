import { useState } from 'react';
import GetBookItemAction from './GetBookItemAction';

function useBookItemState() {
  const [item, setItem] = useState(null);

  const changeHandler = (data) => {
    setItem(data)
  }
  const get = (token ,id) => {
    if(token)
      GetBookItemAction.get(token, id, changeHandler )
  }

  return {book:item, getBookItem:get};
}

export default useBookItemState