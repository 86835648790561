
import 'whatwg-fetch'
import config from 'react-global-configuration';


const ParkActions = {

  getList(params, callback){
     console.log(params)
     
    let url_params = new URLSearchParams();
    url_params.set("embeded","media")
    if(params && params.name)
      url_params.set("name",params.name )
    if(params && params.todofuken)
      url_params.set("todofuken",params.todofuken )
    if(params && params.date)
      url_params.set("date",params.date )
    if(params && params.limit)
      url_params.set("limit",params.limit )
    const query_string =  url_params.toString()


    fetch(config.get("API_PATH")  + "/parks?" + query_string , {
      method: "GET",
      headers: {
       
      }
    }).then(function(response) {

      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {

      if(data && !data.error){
        callback(data)
      }else{
        console.log("error")
      }
    })
  },

  getItem(id, callback){
    
    fetch(config.get("API_PATH")  + "/parks/" + id  , {
      method: "GET",
      headers: {
       
      }
    }).then(function(response) {

      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {

      if(data && !data.error){
        callback(data)
      }else{
        console.log("error")
      }
    })
  },

  /**

  create(company_id, assign_id, item , callback){
    const token = AuthStore.getToken()
    fetch(config.get("API_PATH") + config.get("API_COMPANY") + "/" + company_id + "/assign/" + assign_id + "/mentenances" , {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){

        callback(data)
      
      }else{
        console.log("error")
        
      }
    })
  },


  update(company_id, assign_id, item , callback){
    const token = AuthStore.getToken()
    fetch(config.get("API_PATH") + config.get("API_COMPANY") + "/" + company_id + "/assign/" + assign_id + "/mentenances/"  + item.id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){

        callback(data)
      
      }else{
        console.log("error")
        
      }
    })
  },

 


  delete( company_id, assign_id, item , callback){
    const token = AuthStore.getToken()
    fetch(config.get("API_PATH") + config.get("API_COMPANY") + "/" + company_id + "/assign/" + assign_id + "/mentenances/"  + item.id , {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token,
      },
    
    }).then(function(response) {
      if(response.status === 204  ){
        return {}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      
      if(data && !data.error){
        callback()
      }else{
        console.log("error")
      }
    })
  },
   */
}

export default ParkActions
