import React, { useState, useEffect } from 'react'
import ParkListItem from './ParkListItem';
import useListState from './useListState';

function ParkList(props:PropsValue) {

  const listbox = {
    display: 'flex',
    flexDirection:"column",
    maxWidth:"1024px",
    width:"100%",
    margin:"0 auto"
   }
   
   const list = useListState(props.params)
   const [items, setItems] = useState(null)
   
  useEffect(() => {
    setItems(list.map(item => {
      return <ParkListItem key={"park_" + item.id} item={item} />
    }))
  },[list]);

  return(

      <div style={listbox}>
        {items}
      </div>

  )
}

export default ParkList

