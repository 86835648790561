import React, { useEffect, useCallback } from 'react'

import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import MyPageMenu from './MyPageMenu';
import useAuthState from '../login/useAuthState';
import ProfileUpdateItem from '../profile/ProfileUpdateItem';
function ProfileApp(props:PropsValue) {
  

  const {token, auth} = useAuthState()

  
  useEffect(() => {
    if(token && token.error){
     window.location.href = "/login"
    }
  },[token]);

  const staticAuth = useCallback(auth, [auth])
  useEffect(() => {
    staticAuth()
  },[staticAuth]);


  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          
          <MyPageMenu type="profile" />

          <div className="form">
            <ProfileUpdateItem />
          </div>
          
        </div>
        
      </main>
      
      <Footer />
    </div>

  )
}

export default ProfileApp
