import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom';
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import useParkState from './detail/useParkState';
import ParkDetail from './detail/detail';
import Footer from '../footer/Footer';
import Calendar from './calendar/calendar';

function ParkDetailApp(props:PropsValue) {
  const {id} = useParams()
  const park = useParkState(id)
  const [item, setItem] = useState(null)
  const [selected_offer_id, setSelectedOfferId] = useState(null)
  const [message, setMessage] = useState(null)
  


  useEffect(() => {
    setItem(park)
  },[park]);

  function goToDetail(){
    if(!selected_offer_id){
      setMessage("予約時間を選択してください。")
      return false
    }
    window.location.href = "/book/" + selected_offer_id
  }

  function offerSelectedHandler(id){
    setSelectedOfferId(id)
  }

  return(
    item ? 
    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="detail-maincontent" > 
          <div className="calendarWrap">
            <Calendar id={id} offerSelected={offerSelectedHandler}/>
            <div className="book-btns">
              <button class="progress-btn" onClick={goToDetail}>予約にすすむ</button>
            </div>
          </div>
          <div className="detailWrap">
            <ParkDetail item={item} />
          </div>
        </div>
        
       
        <div className="message">{message}</div>
      </main>
      
      <Footer />
    </div>
    :null
  )
}

export default ParkDetailApp
