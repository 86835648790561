import React, { useState, useEffect } from 'react'
import config from 'react-global-configuration';
import ValueItem from '../form/ValueItem'
import searchBtn from "../image/search.png"
import format from 'date-fns/format';
import Search from './Search'

function SearchBox(props:PropsValue) {


   const select_customStyles = {
    valueContainer : (provided, state) => ({
      ...provided,
      padding:"2px 0 ",
      display:"block",
      fontSize:"14px"
    }),
    menu: (provided, state) => ({
      backgroundColor:"#fff",
      padding: 1,
      marginTop:"-32px"
    }),
    control: () => ({
      border: "0",

      display:"flex",
    }),
    
    indicatorSeparator:() => ({
      width:"0"
    }),
    indicatorsContainer: () => ({
      padding:"0",
      flexDirecrtion:"row", 
      display:"none"
    })
  }

  const [item, setItem] = useState(new Search())

  useEffect(() => {
    return function cleanup() {

    };
  },[]);

  function changeHandler(name,value){
    setItem(item => item.set(name,value))
  }

  function search(){

    let params = new URLSearchParams();
    if(item.name)
       params.set("name",item.name )
    if(item.todofuken)
      params.set("todofuken",item.todofuken)
    if(item.date)
      params.set("date",format(item.date,"yyyy-MM-dd"))

    const query_string =  params.toString()
    window.location.href = "/park?" + query_string 

  }

  return(
    <div  className={"searchBoxWrap"}>
      <div className={"searchBox"}>
        <div className="inputBox inputBoxWithBorder"><ValueItem name="name" placeholder="ゴルフ場名を入力。" label="ゴルフ場" default={item.name} className="search-box-item"  changeHandler={changeHandler} /></div>
        <div className="inputBox inputBoxWithBorder"><ValueItem name="todofuken" type="select" placeholder="都道府県を選択" label="ロケーション" default={item.todofuken} className="search-box-item"  changeHandler={changeHandler} options={config.get("PREFS")} style={select_customStyles}/></div>
        <div className="inputBox"><ValueItem name="date" type="date" placeholder="日付をお選びください。" label="プレー日" default={item.date} className="search-box-item"  changeHandler={changeHandler} options={config.get("PREFS")} /></div>
        <div className="btnBox"><img src={searchBtn} alt="プレー日" onClick={search} /></div>
      </div>
    </div>
  )
}

export default SearchBox

