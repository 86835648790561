
import 'whatwg-fetch'
import config from 'react-global-configuration';


const GetBookListActions = {

  get( token, params, callback){

    let url_params = new URLSearchParams();
   
    if(params && params.target_type)
      url_params.set("target_type",params.target_type )

    const query_string =  url_params.toString()


    fetch(config.get("API_PATH")  + "/books?" + query_string , {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {

      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      callback(data)
    })
  }
}

export default GetBookListActions
