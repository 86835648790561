
import React from 'react'
import {format} from 'date-fns'


function OfferListItem(props:PropsValue) {

  const tableItem = {
    width:"100%",
    display:"flex",
    justifyContent:"flex-end",
    flexDirection:"row",
    alignItems:"center",
    marginBottom:"10px",
    fontSize:"1em"
  }

  const radio ={  
    appearance: "radio",
  }
  
  const radioBox = {
    marginLeft:"10px",
    marginRight:"10px",
    marginTop:"0px",
    display:"flex",
    justifyContent:"center",
    WebkitTransform:"scale(1.5)",
    Transform: "scale(1.5)",
    
  }
  const startTimeBox ={
    marginLeft:"10px",
    
  }

  function changeIdHandler(){
    props.selectedId(props.item.id)
  }


  return(
 
    <div style={tableItem}>
       <div style={radioBox}><input type="radio" name="time" style={radio} onChange={changeIdHandler}/></div>
        <div>{format(props.target_date, "yyyy年M月d日")}</div>
        <div  style={startTimeBox}>{props.item.start_time}〜</div>
        <div  style={startTimeBox}>最大{props.item.partner_number}人</div>
    </div>

  )
}

export default OfferListItem 

