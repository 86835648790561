import { format } from 'date-fns';
import React, { useState, useEffect } from 'react'
import config from 'react-global-configuration';
import useBookItemState from '../useBookItemState';
import Utile from '../../utiles/Utile';
import ValueItem from '../../form/ValueItem';
import useBookPlayerState from '../useBookPlayerState';
import useBookPlayerCreateState from '../useBookPlayerCreateState';

function BookDetail(props:PropsValue) {

  const {book, getBookItem} = useBookItemState()
  const {book_player, updateBookPlayer} = useBookPlayerState(props.id)
  const [book_detail, setBookDetail] = useState(null)
  const [players, setPlayers] = useState(null)
  const { created_book_player, createBookPlayer, deleteBookPlayer} = useBookPlayerCreateState()

  const form = {
    marginTop:"60px"
  }

  /** */
  useEffect(() => {
    if(props.token && props.id){
      getBookItem(props.token, props.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.token]);



  useEffect(() => {
    if(book){
      setBookDetail(
        <div>
          <div class="content-item"><label class="label">ゴルフ場</label><div>{book.offer  ? book.offer.park.name + " (" + Utile.getLabelByValue(config.get("PREFS"), book.offer.park.address.address_todofuken) + ")": null}</div></div>
          <div class="content-item"><label class="label">予約日時</label>{book.offer ? format(new Date(book.offer.target_date), "yyyy年M月d日") : null} {book.offer ? book.offer.start_time: null}〜</div>
        </div>
      ) 
      setPlayers(
        book.players.map(player => {
          return <div class="content-item"><div class="name">{player.name}</div> <div class="deleteBtn" onClick={() => deletePlayerHandler(player)}>削除</div></div>
        })
      )
    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[book]);



  useEffect(() => {
    getBookItem(props.token, props.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.token, props.id, created_book_player]);



  function changeHandler(name,value){
    updateBookPlayer(name,value)
  }

  function addPlayerHandler(){
    createBookPlayer(book_player, props.token)
  }

  function deletePlayerHandler(player){
    deleteBookPlayer(player.id, props.id, props.token)
  }



  return(

      <div className="form">
        <div style={form}>
          <h2>予約詳細</h2>
          <div class="form-content">{book_detail}</div>
          <h2>同行者</h2>
          <div class="form-content">{players}
            {
              book && book.players.length + 1 < book.offer.partner_number ?
              <div class="content-item"><div class="name"><ValueItem name="name" placeholder="同行者名" type="text" label=""className="normal-form" changeHandler={changeHandler}/> </div> <div class="addBtn" onClick={() => addPlayerHandler()}>追加</div></div>
              :null
            }
            
            </div>
        </div>
      </div>

  )
}

export default BookDetail

