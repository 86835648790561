
import React, { useEffect,useState } from 'react'
import ValueItem from '../form/ValueItem';
import config from 'react-global-configuration';
import usePostCodeState from '../postcode/usePostCodeState';
import { useCookies } from 'react-cookie';


function ProfileItem(props:PropsValue) {
  const num_pattern = /^\d*$/

  const [cookies] = useCookies(['user_token']);
  const {address, updatePostCode} = usePostCodeState(null)
  const [profile, setProfile] = useState(null)
  const [post_code_message, setPostcodeMessage] = useState(false)
  const [tel_message, setTelMessage] = useState(false)

  
  const sml = {
    width:"45%"
  }

  const token = cookies["user_token"]
  
  const todofuken_select_style = {
    option: (provided, state) => ({
      ...provided,
    
    }),
    control: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: 400,
      padding: 0,
      borderRadius:0,
      border: "1px solid"

    })
  }

  useEffect(() => {
    if(address && address.length > 0 && address[0].prefCode > 0){
      const _address =  address[0]
      props.changeHandler("address_1",Number(_address.prefCode))
      props.changeHandler("address_2",_address.city)
      props.changeHandler("address_3",_address.town)
      
      props.updated()
    } 
  },[address,props]);

  useEffect(() => { 
    if(props.profile)
      setProfile(props.profile)
  },[props]);




  function changeHandler(name,value){
    props.changeHandler(name,value)
    if(name === "post_code"){
      //半角でなければSearchしない
      if(num_pattern.test(value)){
        setPostcodeMessage(false)
        updatePostCode(value)
      }else{
        setPostcodeMessage(true)
      }
      
    }

    if(name === "tel"){
      if(num_pattern.test(value)){
        setTelMessage(false)
      }else{
        setTelMessage(true)
      }
    }
  }

  function changeAuthHandler(name,value){
    props.changeAuthHandler(name,value)
  }

  return(
    <div  className="form ">
   
        
          <ValueItem name="givenname" style={sml} placeholder="姓" label="姓"  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.givenname : null}/>
          <ValueItem name="firstname" style={sml} placeholder="名" label="名"  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.firstname : null}/>
     
        
        
        <ValueItem name="givenname_furigana" placeholder="フリガナ(姓）" label="フリガナ(姓）"  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.givenname_furigana : null} />
        <ValueItem name="firstname_furigana" placeholder="フリガナ(名）" label="フリガナ(名）"  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.firstname_furigana : null} />
        <ValueItem name="post_code" placeholder="ハイフン無し半角" label="郵便番号"  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.post_code : null} />
        {post_code_message ? <div className="message">半角数字で入力してください。</div> : null}
        <ValueItem name="address_1" placeholder="都道府県" type="select" label="住所"  style={todofuken_select_style} className="login-item"  options={config.get("PREFS")} changeHandler={changeHandler}  defaultValue={profile ? profile.address_1 : null}/>
        <ValueItem name="address_2" placeholder="市区町村" label=""  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.address_2 : null} />
        <ValueItem name="address_3" placeholder="町丁名以下 (マンション名等を含む）" label=""  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.address_3 : null} />
        <ValueItem name="tel" placeholder="ハイフン無し半角" label="電話番号"  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.tel : null} />
        {tel_message ? <div className="message">半角数字で入力してください。</div> : null}
        <ValueItem name="mail" placeholder="" label="メールアドレス"  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.mail : null} />
        <ValueItem name="score" placeholder="" label="ハンディキャップ"  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.score : null} />
        <ValueItem name="gender" placeholder="" type="radio-number" label="性別" options={config.get("GENDERS")} className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.gender : null} />
        <ValueItem name="birthday" placeholder="" label="生年月日" type="split_date"  className="login-item"  changeHandler={changeHandler} defaultValue={profile ? profile.birthday  : null} />
        {!token ? <ValueItem name="password" placeholder="6文字以上で入力してください。" type="password" label="希望するパスワード"  className="login-item"  changeHandler={changeAuthHandler}  /> :null }
        {!token ? <ValueItem name="password_confirm" placeholder="" type="password" label="パスワード(確認）"  className="login-item"  changeHandler={changeAuthHandler}  /> :null }
 
    </div>

  )
}

export default ProfileItem

