import React, { useState, useEffect } from 'react'
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import ValueItem from '../form/ValueItem';
import Progress from './Progress';
import config from 'react-global-configuration';
import { useCookies } from 'react-cookie';

import useUserState from '../user/useUserState';
import useAuthState from '../login/useAuthState';
import Auth from '../login/Auth';
import useProfileCreateState from '../profile/useCreateProfileState';
import {Oval} from 'react-loader-spinner'


function ConfirmProfileApp(props:PropsValue) {

  const {id} = useParams()
  
  const [message, setMessage] = useState(null)
  const [cookies] = useCookies(['profile','user' ,'user_token']);
  const {user, createUser} = useUserState(id)
  const {player, createPlayer} = useProfileCreateState()
  const {token, authentificate} = useAuthState()
  const [profile, setProfile] = useState(null)
  const [auth, setAuth] = useState(null)
  const [tocreate, setTocreate] = useState(false)
  const [is_btn, setIsBtn]   = useState(true)
  const [is_loading, setIsLoading]   = useState(false)

  useEffect(() => {
    //const _profile = 
    if(cookies["profile"]  && cookies["user"]){
      setProfile(cookies["profile"])
      setAuth(cookies["user"])
    }else{
      //TODO
    }
  },[cookies]);


  useEffect(() => {
    if(player && player.error){
  
      //window.location.href = "/user/create/profile/" + id + "?error=1"
    }else if(player && player.id){
      window.location.href ="/user/create/success"
    }
  },[player,id]);


  useEffect(() => {
    if(token && token.error){
      setMessage(<div className='message'>ユーザー登録に失敗しました。アクセスコードが古い可能性があります。<a href="/user/create">仮登録</a>から再度ご登録ください。</div>)
      setIsBtn(true)
      setIsLoading(false)
      return false
    }else{
      if(tocreate){
        createPlayer(token, profile)
      }
    
    }
   
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  
  useEffect(() => {
    
    if(user.error){
      authentificate(new Auth({username:user.username, password:auth.password}))
      setMessage(<div className='message'>ユーザー登録に失敗しました。アクセスコードが古いか、すでに利用済みの可能性があります。</div>)
      return false
    }
    if(user.id){
      authentificate(new Auth({username:user.username, password:auth.password}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user,auth]);

   function regist(){
    setMessage(null)
    setIsBtn(false)
    setIsLoading(true)
   
    if(token){
      createPlayer(token, profile)
    }else{
      setTocreate(true)
      createUser(auth.password)
    }
    
   }

   function back(){
    window.location.href = "/user/create/profile/" + id
   }

   return(

    <div>
      <HeaderImage />
      <Header />
      <main>
      <div className="maincontent"> 
        <div className="create-title" >会員登録：確認</div>
          <Progress type="confirm" />
          <div className="form">
            <ValueItem name="givenname" placeholder="" type="read" label="氏名"  className="login-item" defaultValue={profile ? profile.givenname : null}/>
            <ValueItem name="givenname_furigana" placeholder="" type="read" label="フリガナ"  className="login-item" defaultValue={profile ? profile.givenname_furigana : null}  />
            <ValueItem name="firstname" placeholder="" type="read" label="氏名"  className="login-item" defaultValue={profile ? profile.firstname : null}/>
            <ValueItem name="firstname_furigana" placeholder="" type="read" label="フリガナ"  className="login-item" defaultValue={profile ? profile.firstname_furigana : null}  />
            <ValueItem name="post_code" placeholder="" type="read" label="郵便番号"  className="login-item"  defaultValue={profile ? profile.post_code : null} />
            <ValueItem name="todofuken" placeholder="" type="read_select" label="住所(都道府県）"  className="login-item"  options={config.get("PREFS")} defaultValue={profile ? profile.address_1 : null} />
            <ValueItem name="postcode" placeholder="市区町村" type="read" label=""  className="login-item"  defaultValue={profile ? profile.address_2 : null} />
            <ValueItem name="postcode" placeholder="市区町村以下"  type="read" label=""  className="login-item" defaultValue={profile ? profile.address_3 : null} />
            <ValueItem name="tel" placeholder="" label="電話番号" type="read" className="login-item" defaultValue={profile ? profile.tel : null}  />
            <ValueItem name="tel" placeholder="" label="メールアドレス" type="read" className="login-item" defaultValue={profile ? profile.mail : null} />
            <ValueItem name="tel" placeholder="" label="ハンディキャップ" type="read" className="login-item" defaultValue={profile ? profile.score : null} />
            <ValueItem name="tel" placeholder=""  label="性別"  type="read_select" className="login-item" options={config.get("GENDERS")} defaultValue={profile ? profile.gender : null} />
            <ValueItem name="tel" placeholder="" type="read" label="生年月日"  className="login-item" defaultValue={profile ? format(new Date(profile.birthday), "yyyy年M月d日") : null}/>
            {!token ? <ValueItem name="tel" placeholder="" type="password_read" label="希望するパスワード"  className="login-item" defaultValue={auth ? auth.password : null}/> : null}
          </div>
          {message}
          {is_btn ? 
            <div className="btns">
              <button className="clear-btn" onClick={back}>戻る</button><button className="progress-btn" onClick={regist}>登録</button>
            </div>
          : null }
           {is_loading ? <Oval color="#3aab5e" height={40} width={40} strokeWidth={4}/> : null} 


          </div>
        
        
      </main>
      
      <Footer />
    </div>

  )
}

export default ConfirmProfileApp
