import { addMonths, subMonths } from 'date-fns';
import { useState } from 'react';

function useCurrentMonthState(_current_month) {
  const [current_month, setCurrentMonth] = useState(_current_month);


  const add = () => {
    setCurrentMonth(addMonths(current_month,1))
  }

  const sub = () => {
    setCurrentMonth(subMonths(current_month,1))
  }


  return {current_month:current_month, addMonth:add, subMonth:sub};
}

export default useCurrentMonthState