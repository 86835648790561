import { useState } from 'react';
import OfferActions from '../OfferActions';

function useOfferState() {
  const [items, setItems] = useState([]);


  const handleStatusChange  = (data) => {
    setItems(data)
  }
  const getList = (id, start_date, end_date) => {
    OfferActions.getList( id , start_date, end_date, handleStatusChange);
  }


  return {offers:items, getOfferList:getList};
}

export default useOfferState