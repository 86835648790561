import { useState } from 'react';
import ProfileActions from './ProfileActions';


function useProfileCreateState() {

  const [item, setItem] = useState(null);

  const createHandler = (data) => {
    setItem(data)
  }
  const create = (token, params) => {
    ProfileActions.create(token, params, createHandler)
  };

  return {player:item, createPlayer:create};
}

export default useProfileCreateState