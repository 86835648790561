import React from 'react'
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import Progress from './Progress';

function CreateUserMailSendApp(props:PropsValue) {
 
  const form = {
    margin:"60px auto 60px auto"
  }

  
  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          <div className="create-title" >会員登録：仮登録完了</div>
          <Progress type="preuser" />
          <div style={form}>
            登録いただいたメールアドレスに確認メールを送信しました。メール内のURLをクリックして、登録を続けてください。
          </div>
          
        </div>
        
      </main>
      
      <Footer />
    </div>

  )
}

export default CreateUserMailSendApp
