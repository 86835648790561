import { useState } from 'react';
import BookPlayer from './BookPlayer';

function useBookPlayerState(id) {
  const [item, setItem] = useState(new BookPlayer({book_id:id}));

  const update = (name ,value) => {
    setItem(item => item.set(name,value))
  }

  return {book_player:item, updateBookPlayer:update};
}

export default useBookPlayerState