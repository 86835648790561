import React, {  useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import ParkList from './list_big/ParkList';

import Footer from '../footer/Footer';
import Search from '../searchBox/Search';

function ParkListApp(props:PropsValue) {
  const query = new URLSearchParams(useLocation().search);

  const name = (query.get("name"))
  const todofuken = (query.get("todofuken"))
  const date = (query.get("date"))
  const search = Search({name:name, todofuken:todofuken, date:date})

  useEffect(() => {
    return function cleanup() {
    };
  },[]);

  return(
    <div>
      <HeaderImage />
      <Header />
      <main className="maincontent">
        <ParkList params={search}/>
      </main>
      <Footer />
    </div>
  )
}

export default ParkListApp
