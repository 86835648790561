import React from 'react'
import Utile from '../../utiles/Utile'
import config from 'react-global-configuration';

function ParkDetail(props:PropsValue) {



   const listboxImage = {
    width: '100%',
    objectFit: "cover",
   }

   const name = {
    marginTop:"25px",
    marginBottom:"20px",
    fontSize:"22px"
   }

   const text = {
    lineHeight:"1.8em"
   }

   const btn = {
    width: "100px",
    margin: "20px 0"
   }


   function displayDetail(){
    window.open(props.item.url, '_blank')
   }

  return(
    props.item ? 
      <div className="list-box">
        <div className="listboxImageBox">
            {props.item.medias.length > 0 ? <img  style={listboxImage} src={props.item.medias[0].url} alt="メインイメージ" /> : null}
        </div>
        <div  style={name}>{props.item.name} ({Utile.getLabelByValue(config.get("PREFS") , props.item.address.address_todofuken)})</div>
        <div style={text}>
            アクセス : {props.item.access}</div>
            住所 : {props.item.address.address_less}<br/>
        { props.item.url ? <div className="w100" onClick={displayDetail}><div style={btn} className="black-btn">URL</div></div> : null}
        <div style={text}>{Utile.nl2br(props.item.reguration) }</div>
      </div>
    :null
  )
}

export default ParkDetail 

