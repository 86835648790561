import React, {useCallback, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import config from 'react-global-configuration';
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import BookProgress from './BookProgress';
import useOfferDetailState from '../park/useOfferDetailState';
import Utile from '../utiles/Utile';
import ValueItem from "../form/ValueItem"
import { format } from 'date-fns';
import useBookState from './useBookState';

import useAuthState from '../login/useAuthState';
import useCreateBookState from './useBookCreateState';

function BookConfirmApp(props:PropsValue) {
  const {id} = useParams()
  const {token, auth} = useAuthState()
  const {offer_detail, getOfferDetail} = useOfferDetailState()
  const [select_numbers, setSelectNumbers] = useState([])
  const [message, setMessage] = useState(null)
  const {book, updateBook} = useBookState(id)
  const {created_book, createBook} = useCreateBookState()

  const name = {
    marginTop: "60px",
    fontSize:"20px"
  }

  const text = {
    marginTop: "20px",
    fontSize:"15px"
  }


  const form = {
    marginTop: "20px",
    fontSize:"15px",
    display:"flex",
    flexDirection:"row",
    alignItems:"center"

  }


  const staticAuth = useCallback(auth, [auth])
  useEffect(() => {
    staticAuth()
  },[staticAuth]);

  useEffect(() => {
    if(created_book && created_book.error){
     setMessage("登録エラーが発生しました。この予約は現在受付ができません。")
     return false
    }
    if(created_book){
      window.location.href = "/book/success"
    }
  },[created_book]);



  useEffect(() => {
    if(token && token.error){
     window.location.href = "/login"
    }

  },[token]);



  useEffect(() => {
    if(offer_detail) {
      const list =  (new Array(offer_detail.partner_number)).fill(0).map((i, index) => {
        return {label:(index + 1), value:(index + 1)}
      })
      setSelectNumbers(list)
    }
  },[offer_detail]);

  const staticGetofferDetail = useCallback(getOfferDetail,[getOfferDetail])
  useEffect(() => {
    staticGetofferDetail(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id]);





  function bookEex(){
    if(!book.player_number){
      setMessage("人数を選択してください。")
      return false
    }
    createBook(book,token)
  
   // window.location.href = "/book/success"
  }

  function changeHandler(name, value){
    updateBook(name,value)
  }


  return(
   
    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          <BookProgress type="confirm" />
          <div style={name}>{offer_detail ? offer_detail.park.name + " (" + Utile.getLabelByValue(config.get("PREFS"), offer_detail.park.address.address_todofuken) + ")": null}</div>
          <div style={text}>{offer_detail ? format(new Date(offer_detail.target_date), "yyyy年M月d日") : null} {offer_detail ? offer_detail.start_time: null}〜</div>
          <div style={text}>{offer_detail ? "最大人数 : "  + offer_detail.partner_number + "人" : null}</div>
          <div style={form}>プレイ予定人数 : <ValueItem type="select" placeholder= "人数を選択してください。" options={select_numbers} name="player_number" changeHandler={changeHandler} /></div>
        </div>
        <div className="message">{message}</div>
        <div className="btns">
          <button class="clear-btn">戻る</button><button class="progress-btn" onClick={bookEex}>予約をする</button>
        </div>
      </main>
      
      <Footer />
    </div>

  )
}

export default BookConfirmApp
