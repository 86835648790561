import React  from 'react'

import config from 'react-global-configuration';


function MyPageMenu(props:PropsValue) {

  
 

  
  const list = {
    margin: "30px auto 0 auto",
    width:"100%",
    maxWidth:"1040px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center"
  }

  const selected = {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width:"250px",
    height:"40px",
    backgroundColor:config.get("color_light_green"),
    margin:"0 10px",
    color:"#fff"
  }


  const normal = {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width:"250px",
    height:"40px",
    border: "1px solid " + config.get("color_light_green"),
    margin:"0 10px"
  }




  return(

    <div style={list}>
      <div style={props.type === "coming" ? selected : normal}>{props.type !== "coming" ? <a href="/mypage/book/comming">予約中</a> : <span>予約中</span>}</div>
      <div style={props.type === "history" ? selected : normal}>{props.type !== "history" ? <a href="/mypage/book/history">利用履歴</a> : <span>利用履歴</span>}</div>
      <div style={props.type === "profile" ? selected : normal}>{props.type !== "profile" ? <a href="/mypage/profile">会員情報</a>: <span>会員情報</span>}</div>
    </div>

  )
}

export default MyPageMenu
