
import React, { useState, useEffect } from 'react'
import {format, getWeeksInMonth,setDate, getDay,subDays, addDays,lastDayOfMonth} from 'date-fns'
import useOfferState from './useOfferState'
import OfferList from './offerList'
import useCurrentMonthState from './useCurrentMonthState'

function Calendar(props:PropsValue) {

  const num = {
    backgroundColor:"#fff",
    fontSize:".7em",
    padding:"2px",

    width:"40px",
    margin:"5px auto 0 auto"
  }
  const {current_month, addMonth, subMonth} =  useCurrentMonthState(new Date())
  const {offers,getOfferList} = useOfferState()
  const [tableHtml, setTableHtml] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selected_offers, setSelectedOffers] = useState([])
  const [current_year_month, setCurrentYearMonth] = useState(null)

  useEffect(() => {
 
    if(current_month){
      const month_start_date = setDate(current_month, 1)
      const month_end_date = lastDayOfMonth(current_month)
      setCurrentYearMonth(format(current_month, "yyyy年M月"))
      getOfferList(props.id, format(month_start_date,"yyyy-MM-dd") ,format(month_end_date,"yyyy-MM-dd"))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_month])



  useEffect(() => {  
    createTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[offers,selectedDate]);

  function createTable(){
    
    const month_start_date = setDate(current_month, 1)
    const week_num = getWeeksInMonth(current_month)
    const dif = getDay(month_start_date)
    const target_date = subDays(month_start_date, dif)

    const table_html = (new Array(week_num)).fill(0).map((s, t) => {
      const tds = (new Array(7)).fill(0).map((d, i)=> {
        const current_date = addDays(target_date,(t* 7) + i )
        const className = getTagClass(current_date)
        const is_current_month = (current_month.getMonth() === current_date.getMonth())
      
        return <td  className={className} onClick={() => clickDateHandler(current_date)} key={"trtd_" + t+"_" + i}>
          {is_current_month ? current_date.getDate() : null}
          {getAvailable(current_date).length > 0 ? <div style={num}>{getAvailable(current_date).length}枠</div>: null}
        </td>
      })
      return <tr key={"tr_"  + (offers ? offers.length : "0")  + "_" + t}>{tds}</tr>
    })  

    setTableHtml(table_html)
  }

  function clickDateHandler(date) {
    const _offers = getAvailable(date) 
    if(_offers.length > 0){
      setSelectedOffers(_offers)
      setSelectedDate(date)
    }
  }

  //
  function getTagClass(target_date){
    if(current_month.getMonth() !== target_date.getMonth())  return "outofday"
    if(selectedDate && format(selectedDate, "yyyy-MM-dd") === format(target_date, "yyyy-MM-dd") ) return "selected"
    if(getAvailable(target_date).length > 0 ) return "available"
    return ""
  }
  function getAvailable(target_date){
    if(offers)
      return offers.filter(item => item.target_date === format(target_date, "yyyy-MM-dd"))
    else return []
  }

  function selected(id){
    props.offerSelected(id)
  }

  return(
 
      <div >
        <div className="calendar-selecter">
          {
            current_month > (new Date()) ? <div className="left selectbtn" onClick={subMonth}>◀︎</div> : <div className="left" > </div>
          }
          
          <div>{current_year_month}</div>
          <div className="right selectbtn" onClick={addMonth}>▶︎</div>
        </div>
        <table className="calendar">
          <tbody>
          <tr><td>日</td><td>月</td><td>火</td><td>水</td><td>木</td><td>金</td><td>土</td></tr>
          {tableHtml}
          </tbody>
        </table>
        <OfferList offers={selected_offers} selected={selected}/>
      </div>

  )
}

export default Calendar 

