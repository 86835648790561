
import React, { useState, useEffect, useCallback } from 'react'
import OfferListItem from './offerListItem';



function OfferList(props:PropsValue) {

  const table = {
    width:"100%",
    marginTop:"35px"
  }

  const [list, setList] = useState(null)
  const stableSelectedId = useCallback(selectedId,[props])
  const stableCreateList = useCallback(createList, [props.offers,stableSelectedId])

  useEffect(() => {
    stableCreateList()
  },[stableCreateList]);

  function createList(){
    setList(props.offers.map(offer => {
      const target_date = new Date(offer.target_date)
      return (<OfferListItem item={offer} target_date={target_date} selectedId={stableSelectedId} />)
    }))
  }


  function selectedId(id){
    props.selected(id)
  }


  return(
 
      <div style={table}>
        {list}
      </div>

  )
}

export default OfferList 

