import { useState } from 'react';
import BookPlayer from './BookPlayer';
import BookPlayerActions from './BookPlayerActions';

function useBookPlayerCreateState(id) {
  const [item, setItem] = useState(new BookPlayer({book_id:id}));


  const updateHandler = (data) => {
    setItem(data)
  }
  const create = (book_player ,token) => {
    BookPlayerActions.create(book_player, token, updateHandler)
  }
  const deletePlayer = (id ,book_id, token) => {
    BookPlayerActions.delete(id, book_id, token, updateHandler)
  }

  return {created_book_player:item, createBookPlayer:create, deleteBookPlayer:deletePlayer};
}

export default useBookPlayerCreateState