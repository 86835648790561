import React, {  useEffect } from 'react'
import backgroundImage from "./topbg.svg"


function BigBackGround(props:PropsValue) {

  useEffect(() => {

    return function cleanup() {

    };
  },[]);

  return(

      <div className={"bigBackgroundWrap"}>
        <div className="bigBackground">
          <div><img src={backgroundImage} alt="mainimage" /></div>
          {props.content}
        </div>
      </div>

  )
}

export default BigBackGround

