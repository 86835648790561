import React, { } from 'react'
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import BookProgress from './BookProgress';
function BookSuccessApp(props:PropsValue) {
  
  const mainContent = {
    margin: "80px auto 0 auto",
    width:"100%",
    maxWidth:"1040px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center"
  }

  const name = {
    marginTop: "60px",
    fontSize:"20px"
  }

  const text = {
    marginTop: "20px",
    fontSize:"15px"
  }




  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div style={mainContent}> 
          <BookProgress type="success" />
          <div style={name}>予約が完了しました。</div>
          <div style={text}><a href="/mypage/book/comming">マイページ</a>にてご確認ください。同行者のお名前を登録・キャンセルの場合もマイページからご登録いただけます。</div>
         
        </div>
        
      </main>
      
      <Footer />
    </div>

  )
}

export default BookSuccessApp
