import { format } from 'date-fns';
import React, { useState, useEffect, useCallback } from 'react'
import useBookListState from '../useBookListState';

function BookList(props:PropsValue) {

  const {books, getBookList} = useBookListState()
  const [items, setItems] = useState(null)
  
   
  const staticGetBookList = useCallback(getBookList,[getBookList])
  useEffect(() => {
    if(props.token) {
      staticGetBookList(props.token, props.params)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.token,props.params]);


  useEffect(() => {
    
    setItems(books.map(book => {
      console.log(book)
      return book.offer ?  <div className="book_lsit_item" key={"book_" + book.id }>
        <div className="w150">{format(new Date(book.offer.target_date), "M月d日")}</div>
        <div className="w150">{book.offer.start_time}〜</div>
        <div className="w300">{book.offer.park.name}</div>
        <div className="w150">{book.players.length > book.person_number ? book.players.length : book.person_number}人</div>

        <div className="w150"><a href={"/mypage/book/" + book.id}> 詳細・変更</a></div>
      </div> : null
    }))
  },[books]);

  return(

      <div className="book_lsit">
        <div className="book_lsit_item header">
          <div className="w150">日付</div>
          <div className="w150">開始時間</div>
          <div className="w300">ゴルフ場</div>
          <div className="w150">同行予定人数</div>
          <div className="w150">詳細</div>
        </div>
        {items}
      </div>

  )
}

export default BookList

