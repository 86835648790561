import { useState } from 'react';
import OfferActions from "../park/OfferActions"

function useOfferDetailState() {
  const [item, setItem] = useState(null);

  const handleStatusChange  = (data) => {
    setItem(data)
  }
  const getDetail = (offer_id) => {
    OfferActions.getDetail( offer_id, handleStatusChange);
  }


  return {offer_detail:item, getOfferDetail:getDetail};
}

export default useOfferDetailState