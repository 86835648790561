import React, {  } from 'react'
import Utile from '../../utiles/Utile'
import config from 'react-global-configuration';

function ParkListItem(props:PropsValue) {

  
  const listbox = {
    width: '100%',  
    display:"flex",
    flexDirection:"row",
    marginBottom:"40px"
   }
   const listboxImageBox = {
    width: '330px',
    height: "210px",
    overflow:"hidden",
    marginRight:"40px"
   }

   const listboxImage = {
    width: '100%',
    objectFit: "cover",
   }

   const name = {
    marginTop:"25px",
    marginBottom:"20px",
    fontSize:"22px"
   }

   const text = {
    lineHeight:"2em"
   }

   const btn = {
    width: "200px",
    margin: "20px"
   }

   function displayDetail(){
    window.location.href = "/park/" + props.item.id
   }

  return(

      <div style={listbox}>
        <div style={listboxImageBox}>{props.item.medias.length > 0 ? <img  style={listboxImage} src={props.item.medias[0].url} alt="メインイメージ" /> : null}</div>
        <div>
          <div style={name}>{props.item.name} ({Utile.getLabelByValue(config.get("PREFS") , props.item.address.address_todofuken)})</div>
          <div style={text}>
              アクセス : {props.item.access}</div>
              住所 : {props.item.address.address_less}<br/>
          <div className="w100" onClick={displayDetail}><div style={btn} className="black-btn">予約</div></div>
        </div>
        
      </div>

  )
}

export default ParkListItem

