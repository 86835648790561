import React, {  useEffect } from 'react'
import BigBackGround from '../bigBackGround/BigBackGround';

import SearchBox from "../searchBox/SearchBox"
import Header from "../header/Header"

import CreateUserBtn from "../createUserBtn/CreateUserBtn"
import ParkList from '../park/list/ParkList';

import step1 from "./step1.png"
import step2 from "./step2.png"
import step3 from "./step3.png"
import arrow from "./arrow.png"

import icon_clock from "./icon_clock.png"
import icon_carrie from "./icon_carrie.png"
import icon_cart from "./icon_cart.png"
import icon_exsit from "./icon_exsit.png"
import icon_golf from "./icon_golf.png"
import icon_hand from "./icon_hand.png"
import icon_time from "./icon_time.png"
import icon_tsuchi from "./icon_tsuchi.png"
import Footer from '../footer/Footer';

function TopApp(props:PropsValue) {



  const mainContent = {
    marginTop: "20px",
    width:"100%",
    textAlign:"center"
  }
  const h1 = {
    marginTop:"50px",
    fontSize:"30px",
    width:"100%",
    textAlign:"center",
    fontWeight:"bold"
  }

  const h2 = {
    marginTop:"80px",
   
    marginBottom:"25px",
    fontSize:"28px",
    fontWeight:"bold",
    textAlign:"center"
  }

  const text = {
    marginTop:"40px",
    fontSize:"1em",
    fontWeight:"bold",
    lineHeight:"1.8em",
    textAlign:"center",
    marginBottom:"40px",
  }

  const text2 = {
    textAlign:"center"
  }

  const parklist = {
    marginTop:"64px"
  }



  const h3 = {
    fontSize:"28px",
    color:"#fff",
    fontWeight:"bold",
    textAlign:"center",
    marginBottom:"42px"
  }


  const imgbox = {
    display:"flex",
    justifyContent:"center",
    alignItems:"top",

  }
  const h4 = {
    marginTop:"20px",
    fontSize:"1em",
    color:"#fff",
    fontWeight:"bold",
    textAlign:"center",
  }

  const text4 = {
    marginTop:"10px",
    fontSize:".9em",
    fontWeight:"bold",
    lineHeight:"1.4em",
    textAlign:"center",
    color:"#fff",
  }

  const arrowBox = {
    margin:"0 15px",
    paddingTop:"60px",
    width:"100%",
    
    textAlign:"center"
  }



  const iconbox = {
    width:"100%",
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    justifyContent:"center",
    alignItems:"top",
    margin:"0 auto"
  }

  const iconitem = {
    width:"442px",
    margin:"13px 30px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"center",
    fontSize:".9em"
  }

  const icon = {
    marginRight:"20px"
  }

  const icontext= {
    lineHeight:"1.6em"
  }
  const content = (
    <div style={mainContent}>
      <SearchBox />
    </div>)

  useEffect(() => {

    return function cleanup() {

    };
  },[]);

  return(
    <div>
      <BigBackGround content={content} />
      <Header />
      <main>
      <div className={"createBoxBottom"}>
        <div style={h1}>目土隊とは</div>
        <div style={text}>
        「ナイスショットしたボールがディボット跡に」よくある光景です。<br/>
        せめて目土をしてくれていれば、ライに問題なかったのに。<br/>
        そんな悩みを解決したい！<br/>
        目土隊は無料でプレイしながら目土とピッチマークを直すことにより、<br/>
        良いゴルフ環境、健康増進、技術向上、マナー向上を目指す活動です。<br/>
        あなたの空き時間を、目土隊として活用しませんか？
        </div>


        <CreateUserBtn /></div>
        <div style={h2}>新着募集情報</div>
        <div style={text2}><a href="/">全ての情報を見る</a></div>
        <div style={parklist}><ParkList params={{limit:3}}/></div>

        <div className={"usageBox"} id="usage">
          <div style={h3} >使い方はとても簡単！</div>
          <ul className="stepBox">
            <li >
              <div style={imgbox} className="stepBox-image"><img src={step1} alt=""   /></div>
              <div style={h4}>会員登録</div>
              <div style={text4}>PCまたはスマートフォンから<br/>会員登録をする。</div>
            </li>
            <li>
              <div className="arrowBox" style={arrowBox}><img src={arrow} alt=""   /></div>
            </li>
            <li>
              <div style={imgbox} className="stepBox-image"><img src={step2} alt=""   /></div>
              <div style={h4}>検索する</div>
              <div style={text4}>ゴルフ場、ローケーション、プレー日の<br/>どれかを入力するだけ。</div>
            </li>
            <li>
              <div className="arrowBox" style={arrowBox} ><img src={arrow} alt=""   /></div>
            </li>
            <li>
              <div style={imgbox} className="stepBox-image"><img src={step3} alt=""  /></div>
              <div style={h4}>予約する</div>
              <div style={text4}>ご希望のゴルフ場を見つけたら<br/>予約するだけ。</div>
            </li>
          </ul>
        </div>
        <div className={"createBoxBottom"} id="notice"><CreateUserBtn /></div>

        <div className="title5" > これだけ守れば、無料！ </div>
        <div >
          <ul style={iconbox}>
            <li style={iconitem}>
              <div  className="icon" style={icon}><img src={icon_clock} alt="アイコン" /></div>
              <div style={icontext}>
              一般のプレーヤーの最終組がスタートした後に <br/>
            （通常14時以降）に、「目土隊」プレーヤーがスタート。

              </div>
            </li>
            <li style={iconitem}>
              <div   className="icon" style={icon}><img src={icon_exsit} alt="アイコン" /></div>
              <div style={icontext}>
              最終組スタート後、ゴルフ場の設定した時間に登録いただきます。<br/>詳細は、各ゴルフ場の指示に従っていただきます。  


              </div>
            </li>

            <li style={iconitem}>
              <div  className="icon" style={icon}><img src={icon_hand} alt="アイコン" /></div>
              <div style={icontext}>
              ディボットに⽬⼟をしながら、歩いてプレーしていただきます。

              </div>
            </li>

            <li style={iconitem}>
              <div className="icon" style={icon}><img src={icon_cart} alt="アイコン" /></div>
              <div style={icontext}>
              歩いての作業の為、カートは使用できません。

              </div>
            </li>


            <li style={iconitem}>
              <div className="icon" style={icon}><img src={icon_carrie} alt="アイコン" /></div>
              <div style={icontext}>
              手引カートもしくは、軽量クラブケースをご使用になる場合は、ご自身で用意していただきます。

              </div>
            </li>


            <li style={iconitem}>
              <div className="icon" style={icon}><img src={icon_tsuchi} alt="アイコン" /></div>
              <div style={icontext}>
              1ホール1袋分の砂をディボットに撒きながらプレーしていただき、グリーン上のピッチマークもグリーンフォークで修復してください。 


              </div>
            </li>

            <li style={iconitem}>
              <div className="icon" style={icon}><img src={icon_time} alt="アイコン" /></div>
              <div style={icontext}>
              ハーフ9Hを2時間10分以内で回れる方のみ募集対象になります。


              </div>
            </li>

            <li style={iconitem}>
              <div className="icon" style={icon}><img src={icon_golf} alt="アイコン" /></div>
              <div style={icontext}>
              当日プレーする人数は、1組一人でも四人でも自由です。基本組み合わせはしませんが、一部のコースでは組み合わせになる場合があります。あらかじめご了承ください。
              </div>
            </li>

          </ul>
          <div className="createBoxBottom"><CreateUserBtn /></div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default TopApp
