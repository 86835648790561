import { useState } from 'react';
import Book from './Book';

function useBookState(id) {
  const [item, setItem] = useState(new Book({offer_id:id}));


  const update = (name ,value) => {
    setItem(item => item.set(name,value))
  }

  return {book:item, updateBook:update};
}

export default useBookState