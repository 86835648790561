import { useState } from 'react';
import UserActions from './UserActions';

function useUserState(secret) {

  const [item, setItem] = useState([]);

  function handleStatusChange(data) {
    setItem(data);
  }


  const createUser = (password) => {
    UserActions.create(password, secret, handleStatusChange);
  };


  return  {user:item, createUser:createUser};
}

export default useUserState