import { useState } from 'react';
import Auth from './Auth';
import AuthActions from './AuthActions';


function useAuthState() {

  const [item, setItem] = useState(new Auth())
  const [token, setToken] = useState(null);

  const auth = () => {

    const _token = AuthActions.getToken()
    setToken(_token)
  }

  const authHandler = (data) => {
    console.log(data)
    if(data.error){
      setToken(data)
    }else{
      setToken(data.access_token)
    }
  }

  const updateItem = (name ,value) => {
    setItem(item => item.set(name, value))
  }

  const authentificate = (auth) => {
    //AuthActions.logout()
    AuthActions.auth(auth, authHandler);
  };  

  const getToken = () => {
    setToken(AuthActions.getToken())
  };

  const removeCookies = () => {
    AuthActions.logout()
  };


  return {token:token, logout:removeCookies, updateAuthItem:updateItem, authentificate:authentificate, auth_item:item, auth:auth, getToken:getToken};
}

export default useAuthState