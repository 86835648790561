import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

import HeaderImage from '../headerImage/HeaderImage';

import Header from "../header/Header"
import Footer from '../footer/Footer';
import useAuthState from '../login/useAuthState';
import MyPageMenu from './MyPageMenu';

import BookDetail from '../book/detail/BookDetail';

function BookDetailApp(props:PropsValue) {

  const {id} = useParams()
  const {token, auth} = useAuthState()
  
 
   useEffect(() => {
    if(token && token.error){
     window.location.href = "/login"
    }
  },[token]);

  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);





  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          <MyPageMenu type="bookitem" />
          <BookDetail token={token} id={id} />
        </div>
      </main>
      
      <Footer />
    </div>

  )
}

export default BookDetailApp
