import { useState, useEffect } from 'react';
import ParkActions from '../ParkActions';

function useParkState(id) {
  const [item, setItem] = useState(null);

  useEffect(() => {
    function handleStatusChange(item) {

      setItem(item);
    }

    ParkActions.getItem( id , handleStatusChange);
    
  },[id]);

  return item;
}

export default useParkState