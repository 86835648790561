import React, { useState,useEffect } from 'react'

import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import ValueItem from '../form/ValueItem';
import Question from './Question';
import useQuestionState from './useQuestionState';

function QuestionApp(props:PropsValue) {
  const btnBox = {

    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    cursor:"pointer"
  }

  const [question, setQuestion] = useState(new Question())
  const [message, setMessage] = useState(null)

  const {create_question_result, createQuestion} = useQuestionState()
  const changeHandler = (name, value) => {

    const q = question.set(name, value)
    setQuestion(q)
  }

  useEffect(() => {
    if(create_question_result){
      if(create_question_result && create_question_result.body ) window.location.href = "/question/success"
      else if(create_question_result && create_question_result.error )setMessage("送信に失敗しました。メールアドレスをご確認ください。")
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[create_question_result]); 

  const send = () => {
    if( question.name === ""){
      setMessage("お名前を入力してください。")
    }else  if( question.mail === ""){
      setMessage("メールアドレスを入力してください。")
    }else  if( question.content === ""){
      setMessage("お問い合わせ内容を入力してください。")
    }else{
      setMessage(null)
      createQuestion(question)
    }
  }
  return(
    
   
    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="page-maincontent" > 
          <h1>お問い合わせ</h1>
          <div className="question"> 
          <div >
            <ValueItem name="name" type="text" placeholder="お名前を入力してください。" label="お名前" className="login-item"  changeHandler={changeHandler}  />
          </div>

          <div>
            <ValueItem name="mail" placeholder="メールアドレスを入力してください。" label="メールアドレス"  className="login-item"  changeHandler={changeHandler} />
          </div>

          <div>
            <ValueItem name="content" type="textarea" placeholder="" label="お問い合わせ内容"  className="login-item"  changeHandler={changeHandler} />
          </div>

          
          <div className='message'>{message}</div>
          <div style={btnBox} onClick={send}><button className="big-btn">送信</button></div>
        </div>
          </div>
      </main>
<Footer />
</div>

  )
}

export default QuestionApp
