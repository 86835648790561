
import React, { useEffect, useCallback } from 'react'

import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import useAuthState from '../login/useAuthState';
import MyPageMenu from './MyPageMenu';
import BookList from '../book/list/BookList';

function HistoryBookApp(props:PropsValue) {
  
  const {token, auth} = useAuthState()
  const params = {target_type:"history"}
  
  useEffect(() => {
    if(token && token.error){
     window.location.href = "/login"
    }
  },[token]);

  const staticAuth = useCallback(auth, [auth])
  useEffect(() => {
    staticAuth()
  },[staticAuth]);


  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div className="maincontent"> 
          <MyPageMenu type="history" />
          <BookList token={token} params={params} />
        </div>
      </main>
      
      <Footer />
    </div>

  )
}

export default HistoryBookApp
