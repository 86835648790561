import React, {   useEffect ,useState} from 'react'

 

function MonthSelect(props:PropsValue){
  
  const [options, setOptions] = useState(null)

  useEffect(() => { 
    const start_month = 1
    const _options =  (new Array(12)).fill(0).map((n, index) => {
      const _value = Number(start_month + index)
      const selected = _value === Number(props.defaultValue) ? "selected" : null

      return <option selected={selected} value={_value} >{_value}</option>
    }
       
    )
    
    setOptions(<select onChange={changeHandler}>{_options}</select>)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.defaultValue]);


  function changeHandler(e){
   
    props.changeHandler("month",e)
  }



  return (
    <div className={props.className}>
     
      {options}
     
    </div>
  );
}

export default MonthSelect

