import { useState } from 'react';
import GetBookListActions from './GetBookListActions';

function useBookListState() {
  const [items, setItems] = useState([]);

  const changeHandler = (data) => {

    setItems(data)
  }
  const get = (token ,params) => {

    GetBookListActions.get(token, params,changeHandler )
  }

  return {books:items, getBookList:get};
}

export default useBookListState