
import { Record } from 'immutable'
const Profile = Record({
  firstname:null,
  firstname_furigana:null,
  givenname:null,
  givenname_furigana:null,
  post_code:null,
  address_1:null,
  address_2:null,
  address_3:null,
  tel:null,
  mail:null,
  score:null,
  gender:null,
  birthday:new Date("1970-01-01"),
});
export default Profile;
