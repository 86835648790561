import React, {  } from 'react'


function BigBtn(props:PropsValue) {
  const boxWrap= {
    width:"100%",
    display: 'flex',
    justifyContent:"center"
  }


  return(
    <div  style={boxWrap}>
      <div className="big-btn" onClick={props.createHandler}>
        {props.text}
      </div>
    </div>
  )
}

export default BigBtn

