import { useState, useEffect } from 'react';
import Profile from './Profile';


function useProfileState(id) {

  const [profile, setItem] = useState(null);

  useEffect(() => {
    if(id) {
    }else{
      setItem(profile => new Profile())
    }
    
  },[id]);

  const update = (name,value) => {
    setItem(profile  => profile.set(name,value));
  };


  const create = (params) => {
    setItem(new Profile(params));
  };

  return {profile:profile, updateProfile:update, createProfile:create};
}

export default useProfileState